@import '~font-awesome/css/font-awesome.css';

/* layout css */
.layout {
  display: flex;
  min-height: 100vh;
  flex-wrap: nowrap;
}

/* content css */
.content {
  flex-grow: 1;
  background-color: #fff;
  padding: 70px 50px 70px 50px;
  min-height: 100vh;
  z-index: 1;
}

/* nav bar css */
.nav-bar {
  height: 60px;
}

/* header css */
.header {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: #cdd0d3 !important;
  z-index: 2;
}

.header-toggle-button {
  border-width: 1px !important;
  margin: 0.5% 0.5% 0.5% 0;
  padding-top: 0.2% !important;
}

/* avatar css */
.user-info {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px #ccc;
  padding: 5%;
}

/* side bar css */
.sidebar {
  min-width: 200px;
  background-color: #f8f9fa;
  padding-top: 31px;
}

/* Add styles for active and inactive sidebar links in light mode */
.sidebar .list-group-item.active {
  background-color: white;
  color: #007bff;
  border-left: 3px solid #007bff;
}

.sidebar .list-group-item.active a {
  text-decoration: none;
  color: blue;
}

.sidebar .list-group-item.inactive {
  background-color: #f8f9fa;
  color: grey;
  border-left: 3px solid grey;
}

.sidebar .list-group-item.inactive a {
  text-decoration: none;
  color: grey;
}

.breadcrumb {
  background-color: transparent !important;
  display: flex;
}

/* footer css */
.footer {
  position: fixed;
  padding: 0 1%;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
  margin-top: auto;
  z-index: 2;
}

/* loading css */
.loading-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  cursor: progress;
  background-color: #51aded;
  opacity: 0.8;
}

.loading-background .loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  opacity: 1 !important;
}

/* Agrid Table */
.ag-paging-panel {
  height: 50px !important;
  border-left: 1px solid #6c757d !important;
  border-right: 1px solid #6c757d !important;
  border-bottom: 1px solid #6c757d !important;
  border-top: none !important;
}

.ag-status-bar {
  display: none !important;
}

/* staff profile cards */
.staff-profile-card {
  margin-top: 1%;
}

.staff-profile-tabs {
  margin-top: 0%;
}

/* nav bar pills */

.nav-pills .nav-link.active {
  color: black !important;
  background-color: transparent !important;
  border: 1px solid #51aded !important;
}

.form-text-red {
  color: red !important;
}

/* remove border of DateTimePicker*/
.react-datetime-picker__wrapper {
  border: none !important;
}
