.dark-mode body {
  background-color: #2e2e2e;
  color: #e0e0e0;
}

.dark-mode .header,
.dark-mode .footer,
.dark-mode .sidebar,
.dark-mode .content {
  background-color: #3a3a3a;
  color: #e0e0e0;
}

.dark-mode .nav-bar,
.dark-mode .nav-pills .nav-link.active {
  background-color: #4a4a4a;
  color: #e0e0e0;
}

.dark-mode .form-text-red {
  color: #ff6b6b;
}

/* Add styles for Bootstrap cards */
.dark-mode .card {
  background-color: #3a3a3a;
  color: #e0e0e0;
  border-color: #4a4a4a;
}

.dark-mode .card-header,
.dark-mode .card-footer {
  background-color: #4a4a4a;
  color: #e0e0e0;
}

/* Add styles for tables */
.dark-mode table {
  background-color: #3a3a3a;
  color: #e0e0e0;
  border-color: #4a4a4a;
}

.dark-mode th,
.dark-mode td {
  border-color: #4a4a4a;
}

.dark-mode thead {
  background-color: #4a4a4a;
  color: #e0e0e0;
}

.dark-mode tbody tr:nth-child(even) {
  background-color: #3a3a3a;
}

.dark-mode tbody tr:nth-child(odd) {
  background-color: #2e2e2e;
}

/* Add styles for dropdowns */
.dark-mode .dropdown-menu {
  background-color: #3a3a3a;
  color: #e0e0e0;
}

.dark-mode .dropdown-item {
  background-color: #3a3a3a;
  color: #e0e0e0;
}

.dark-mode .dropdown-item:hover,
.dark-mode .dropdown-item:focus {
  background-color: #4a4a4a;
  color: #ffffff;
}

/* Add styles for inputs, selects, and textareas */
.dark-mode input,
.dark-mode select,
.dark-mode textarea {
  background-color: #4a4a4a;
  color: #a0a0a0;
  border: 1px solid #5a5a5a;
}

.dark-mode input:focus,
.dark-mode select:focus,
.dark-mode textarea:focus {
  background-color: #ffffff;
  color: #2e2e2e;
  border-color: #5a5a5a;
}

/* Add styles for disabled elements */
.dark-mode input:disabled,
.dark-mode select:disabled,
.dark-mode textarea:disabled,
.dark-mode button:disabled {
  background-color: #5a5a5a;
  color: #a0a0a0;
  border-color: #6a6a6a;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Add styles for headings */
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6 {
  color: #e0e0e0;
}

/* Add styles for nav-tabs */
.dark-mode .nav-tabs .nav-link {
  background-color: #3a3a3a;
  color: #e0e0e0;
  border: 1px solid #4a4a4a;
}

.dark-mode .nav-tabs .nav-link.active {
  background-color: #4a4a4a;
  color: #ffffff;
  border-color: #5a5a5a;
}

/* Add styles for sidebar */
.dark-mode .sidebar {
  background-color: #3a3a3a;
  color: #e0e0e0;
}

.dark-mode .sidebar a {
  color: #e0e0e0;
}

.dark-mode .sidebar a:hover {
  color: #ffffff;
}

/* Add styles for AgGrid table */
.dark-mode .ag-theme-fresh {
  background-color: #2e2e2e;
  /* Darker background */
  color: #e0e0e0;
}

.dark-mode .ag-theme-fresh .ag-header {
  background-color: #3a3a3a;
  /* Darker header background */
  color: #e0e0e0;
  /* Darker header text */
}

.dark-mode .ag-theme-fresh .ag-row {
  background-color: #3a3a3a;
  color: #e0e0e0;
}

.dark-mode .ag-theme-fresh .ag-row:nth-child(even) {
  background-color: #2e2e2e;
}

.dark-mode .ag-theme-fresh .ag-row:hover {
  background-color: #4a4a4a;
  color: #ffffff;
}

.dark-mode .ag-theme-fresh .ag-cell {
  border-color: #4a4a4a;
}

/* Add styles for when there are no lines */
.dark-mode .ag-theme-fresh .ag-body-viewport {
  background-color: #2e2e2e;
  /* Darker background when no lines */
}

.dark-mode .ag-theme-fresh .ag-cell {
  border-color: #4a4a4a;
}

.dark-mode .ag-theme-fresh .ag-header-cell,
.dark-mode .ag-theme-fresh .ag-header-cell-sortable {
  background-color: #3a3a3a;
  /* Darker header background */
  color: #e0e0e0;
  /* Darker header text */
  border-color: #4a4a4a;
  /* Darker border color */
}

.dark-mode .ag-theme-fresh .ag-header-cell:hover,
.dark-mode .ag-theme-fresh .ag-header-cell-sortable:hover {
  background-color: #4a4a4a;
  /* Darker hover background */
  color: #ffffff;
  /* Lighter hover text */
}

/* Add styles for active and inactive sidebar links in dark mode */
.dark-mode .sidebar .list-group-item.active {
  background-color: #4a4a4a;
  color: #ffffff;
  border-left: 3px solid #007bff;
}

.dark-mode .sidebar .list-group-item.active a {
  text-decoration: none;
  color: #007bff;
}

.dark-mode .sidebar .list-group-item.inactive {
  background-color: #3a3a3a;
  color: #a0a0a0;
  border-left: 3px solid grey;
}

.dark-mode .sidebar .list-group-item.inactive a {
  text-decoration: none;
  color: #a0a0a0;
}

/* Add styles for buttons */
.dark-mode .btn {
  background-color: #4a4a4a;
  color: #e0e0e0;
  border-color: #5a5a5a;
}

.dark-mode .btn:hover,
.dark-mode .btn:focus,
.dark-mode .btn:active {
  background-color: #5a5a5a;
  color: #ffffff;
  border-color: #6a6a6a;
}

.dark-mode .btn-outline {
  background-color: transparent;
  color: #e0e0e0;
  border-color: #5a5a5a;
}

.dark-mode .btn-outline:hover,
.dark-mode .btn-outline:focus,
.dark-mode .btn-outline:active {
  background-color: #5a5a5a;
  color: #ffffff;
  border-color: #6a6a6a;
}

.dark-mode .btn-primary {
  background-color: #007bff;
  color: #ffffff;
  border-color: #0056b3;
}

.dark-mode .btn-primary:hover,
.dark-mode .btn-primary:focus,
.dark-mode .btn-primary:active {
  background-color: #0056b3;
  color: #ffffff;
  border-color: #004085;
}

.dark-mode .btn-secondary {
  background-color: #6c757d;
  color: #ffffff;
  border-color: #545b62;
}

.dark-mode .btn-secondary:hover,
.dark-mode .btn-secondary:focus,
.dark-mode .btn-secondary:active {
  background-color: #545b62;
  color: #ffffff;
  border-color: #3d434a;
}

.dark-mode .btn-success {
  background-color: #28a745;
  color: #ffffff;
  border-color: #1e7e34;
}

.dark-mode .btn-success:hover,
.dark-mode .btn-success:focus,
.dark-mode .btn-success:active {
  background-color: #1e7e34;
  color: #ffffff;
  border-color: #155724;
}

.dark-mode .btn-danger {
  background-color: #dc3545;
  color: #ffffff;
  border-color: #bd2130;
}

.dark-mode .btn-danger:hover,
.dark-mode .btn-danger:focus,
.dark-mode .btn-danger:active {
  background-color: #bd2130;
  color: #ffffff;
  border-color: #8b1e24;
}

.dark-mode .btn-warning {
  background-color: #ffc107;
  color: #212529;
  border-color: #e0a800;
}

.dark-mode .btn-warning:hover,
.dark-mode .btn-warning:focus,
.dark-mode .btn-warning:active {
  background-color: #e0a800;
  color: #212529;
  border-color: #c69500;
}

.dark-mode .btn-info {
  background-color: #17a2b8;
  color: #ffffff;
  border-color: #117a8b;
}

.dark-mode .btn-info:hover,
.dark-mode .btn-info:focus,
.dark-mode .btn-info:active {
  background-color: #117a8b;
  color: #ffffff;
  border-color: #0d5a6e;
}

.dark-mode .btn-light {
  background-color: #f8f9fa;
  color: #212529;
  border-color: #dae0e5;
}

.dark-mode .btn-light:hover,
.dark-mode .btn-light:focus,
.dark-mode .btn-light:active {
  background-color: #dae0e5;
  color: #212529;
  border-color: #c6cbd1;
}

.dark-mode .btn-dark {
  background-color: #343a40;
  color: #ffffff;
  border-color: #1d2124;
}

.dark-mode .btn-dark:hover,
.dark-mode .btn-dark:focus,
.dark-mode .btn-dark:active {
  background-color: #1d2124;
  color: #ffffff;
  border-color: #0c0e0f;
}
